<template>
  <div v-if="org">
    <v-toolbar Zdark elevation="0">
      <v-spacer />
    </v-toolbar>
    <v-container class="info-container grey lighten-5 py-0">
      <v-row>
        <v-col cols="12" sm="6">
          <h3>General Info</h3>
          <div class="label">ID</div>
          <div class="value"><pre>{{org.id}}</pre></div>
          
          <div class="label">Name</div>
          <div class="value">{{org.name}}</div>
          
          <div class="label">Contact</div>
          <div class="value">{{org.email}} <v-btn icon color="accent" small @click="$helpers.copyToClipboard(org.email, $toast)"><v-icon small class="mr-2">fa-copy</v-icon></v-btn></div>

            
        </v-col>
        <v-col cols="12" sm="6">
          <h3>License Info</h3>
          <div class="label">License</div>
          <div class="value">
            {{licenseInfo && licenseInfo.license | license }}
            <v-btn color="accent" outlined small class="ms-2" @click="showLicenseDialog=true">
              Apply
            </v-btn>
          </div>
          
          <div class="label">Purchased users</div>
          <div class="value">{{licenseInfo && licenseInfo.max_capacity}} (invoiced: {{ orgUtil.getMetaIntValue('invoiced.count') || '-' }})</div>
          
          <div class="label">Joined users</div>
          <div class="value">{{licenseInfo && licenseInfo.current_capacity}}</div>
          
          <div class="label">License valid?</div>
          <div class="value">{{ licenseInfo && licenseInfo.valid }}</div>
                    
        </v-col>
      </v-row>
    </v-container>

    <div class="pa-4">
      <h3>Meta</h3>
      <v-data-table
        :headers="[ { text: 'Key', value: 'key' }, { text: 'Value (String)', value: 'val_str' }, { text: 'Value (Int)', value: 'val_int' }, { text: 'Value (Date)', value: 'val_dto' }, { text: 'Value (Double)', value: 'val_dbl' }  ]"
        :items="org.meta"
        :items-per-page="50"
        class="elevation-1 mb-8"
        />
    </div>

    <multi-step-dialog v-model="showLicenseDialog" title="Apply License" confirm-label="Apply" single-step @confirm="applyLicense">
      <v-card-text>
        <v-switch v-model="licenseRequest.invoiced" label="Invoice created in MoneyBird" />

        <ToggleButtonInput
          v-model="licenseRequest.license"
          label="License"
          :items="[{type:null, text: 'None'}, {type:'BASIC', text: 'Starter'}, {type:'PRO', text: 'Pro'}, {type:'ENTERPRISE', text: 'Enterprise'}]"
          />

        <v-text-field v-model.number="licenseRequest.quantity" type="number" min="10" label="Number of participants" />

      </v-card-text>
    </multi-step-dialog>

  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import adminService from "@/services/adminService";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import ToggleButtonInput from "@/components/ToggleButtonInput";
import MultiStepDialog from '@/components/generic/MultiStepDialog.vue';
import OrgUtil from "@/util/orgUtil";
import EventGrid from "@/components/EventGrid.vue";

export default {
  name: "AdminOrgDetails",
  components: {
    EventGrid,
    MultiStepDialog,
    ToggleButtonInput,
  },
  props: {
    org: Object,
    tenant: Object,
  },
  data() {
    return {
      error: null,
      licenseInfo: null,
      showLicenseDialog: false,
      licenseRequest: {},
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {

    async loadData() {
      console.log('Loading event')
      if (this.user && this.org) {
        let id = this.$route.params.id;
        this.licenseInfo = (await orgManagerService.getLicenseStatus(id)).data;
        console.log('this.licenseInfo', this.licenseInfo);
        this.licenseRequest = {
          invoiced: this.orgUtil.hasMeta('invoiced.count'),
          license: this.licenseInfo.license,
          quantity: this.orgUtil.getMetaIntValue('invoiced.count') || 0,
        };
      }
    },
    async applyLicense() {
      console.log('Apply license now', this.licenseRequest);
      var result = (await adminService.applyOrgLicense(this.org.id, this.licenseRequest.license, this.licenseRequest.quantity, this.licenseRequest.invoiced)).data;
      if (result.status == 'OK') {
        this.licenseRequest = {};
      }
      this.orgUtil.refresh();
      this.loadData();
    },
    

  },
  computed: {
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    org() {
      if (this.licenseInfo == null) {
        this.loadData();
      }
    },
  }

};
</script>
<style lang="scss">

.search-box {
  max-width: 350px;
  float: right;
}
</style>

